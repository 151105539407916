import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { Dispatch, QueryRequest, store } from "../../../utils/types";
import {
  queryWithCurrentQueryDetails,
  updateCurrentQueryDetiails,
} from "../../../actions/query";
import { useHistory } from "react-router-dom";
import useAPI from "../../../api/useAPI";
import { useEffect } from "react";
import { useStore } from "../../../reducers/zustand/store";
import { Button } from "antd";

const Search = ({
  values,
  queryWithCurrentQueryDetailsAction,
  setValues,
}: any) => {
  const history = useHistory();
  const api = useAPI();
  const [valid, setValid] = useState(false);
  const { setLinks, setNodes, formFields, loading, setLoading } = useStore(
    (state: any) => ({
      formFields: state.formFields,
      patchFormFields: state.patchFormFields,
      setNodes: state.setNodes,
      setLinks: state.setLinks,
      loading: state.loading,
      setLoading: state.setLoading,
    })
  );
  const onSubmit = () => {
    setLoading(true);
    const cellLine = formFields.cellLine === "All" ? [] : Array(formFields.cellLine);
    const queryRequest = {
      ...formFields,
      cellLine,
    };
    api.fetchGeneOrDrugData(queryRequest, (res) => {
      if (res) {
        setLinks(
          res.data.data.links.map((link: any) => ({
            ...link,
            start: +link.start,
            end: +link.end,
          }))
        );
        setNodes(res.data.data.nodes);
        setValues(formFields);
        setLoading(false);
        history.push("/data");
      }
    });
  };

  useEffect(() => {
    const isValid =
      formFields.geneOrDrug &&
      formFields.isActivator !== null &&
      formFields.pertType !== null &&
      formFields.cellLine !== null;

    setValid(isValid);
  }, [
    formFields.geneOrDrug,
    formFields.isActivator,
    formFields.pertType,
    formFields.cellLine,
  ]);

  return (
    <div id="query-search-button" className="flex flex-row-reverse mt-5">
      <Button
        disabled={!valid}
        className="px-4 py-1 text-base font-medium button-search unselectable"
        onClick={onSubmit}
        loading={loading}
      >
        Search
      </Button>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
